<template>
  <component :is="opportunityData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="opportunityData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.opportunities.opportunityNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-opportunity-list' }">
          {{ $t("message.opportunities.opportunityList") }}
        </b-link>
        {{ $t("message.opportunities.forOtheropportunities") }}
      </div>
    </b-alert>

    <template v-if="opportunityData">
      <div class="cardTableProject">
        <b-card>
          <h2 class="mb-2">
            {{ $t("message.opportunities.editOpportunities") }}
          </h2>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(updateOpp())">
              <b-row>
                <b-col md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-group label-for="nameOpportunities">
                      <template v-slot:label>
                        {{ $t("message.tableHeader.name") }}
                      </template>
                      <b-form-input
                        v-model="opportunityData.name"
                        class="mt-0"
                        label="number"
                        label-for="nameOpportunities"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- customer -->
                <b-col md="3">
                  <b-form-group label-for="costumer">
                    <template v-slot:label>
                      {{ $t("message.customers.customer") }}
                    </template>
                    <v-select
                      v-model="opportunityData.customerId"
                      :options="customerOptions"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      class="mt-0"
                    />
                  </b-form-group>
                </b-col>

                <!-- status -->
                <b-col md="3">
                  <b-form-group label-for="costumer">
                    <template v-slot:label>
                      {{ $t("message.customers.status") }}
                    </template>
                    <v-select
                      v-model="opportunityData.status"
                      :options="statusOptions"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      class="mt-0"
                    />
                  </b-form-group>
                </b-col>

                <!-- Responsible -->
                <b-col md="3">
                  <b-form-group label-for="costumer">
                    <template v-slot:label>
                      {{ $t("message.list.accountable") }}
                    </template>
                    <v-select
                      v-model="opportunityData.userId"
                      :options="responsibleOptions"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      class="mt-0"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label-for="startOpportunities">
                    <template v-slot:label>
                      {{ $t("message.date.start") }}
                    </template>
                    <flat-pickr
                      id="opportunity-date"
                      v-model="opportunityData.dateCreation"
                      class="mt-0 form-control"
                      :config="flatConfig"
                      placeholder="DD-MM-YYYY"
                    />
                  </b-form-group>
                </b-col>

                <!-- end -->
                <b-col md="3">
                  <b-form-group label-for="opportunityDate">
                    <template v-slot:label>
                      {{ $t("message.date.end") }}
                    </template>
                    <flat-pickr
                      id="opportunity-date"
                      v-model="opportunityData.dateConclusion"
                      class="mt-0 form-control"
                      :config="flatConfig"
                      placeholder="DD-MM-YYYY"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="5" v-if="!opportunityData.international">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.expenses.value") }}
                    </template>
                    <b-form-input
                      label="value"
                      v-money="getCurrencyFormat()"
                      v-model="opportunityData.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      class="mt-0"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3" v-else>
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.expenses.value") }}
                    </template>
                    <b-form-input
                      label="value"
                      v-money="getCurrencyFormat()"
                      v-model="opportunityData.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      class="mt-0"
                    />
                  </b-form-group>
                </b-col>

                <!-- International -->
                <b-col md="1">
                  <b-form-group label-for="deadlineTasks">
                    <template v-slot:label>
                      {{ $t("message.expenses.international") }}
                    </template>
                    <b-form-checkbox
                      style="margin-left: 25px; margin-top: 10px"
                      v-model="opportunityData.international"
                      name="is-vertical-menu-collapsed"
                    />
                  </b-form-group>
                </b-col>

                <!-- International Value -->
                <b-col md="2" v-if="opportunityData.international">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.expenses.price") }}
                    </template>
                    <b-form-input
                      id="value"
                      v-model="opportunityData.internationalPrice"
                      v-money="getCurrencyFormat()"
                    />
                  </b-form-group>
                </b-col>

                <!-- description -->
                <b-col md="12">
                  <b-form-group label-for="descriptionTasks">
                    <template v-slot:label>
                      {{ $t("message.projects.description") }}
                    </template>
                    <quill-editor
                      id="title"
                      style="margin-bottom: 52px; height: 200px"
                      :options="editorOption"
                      v-model="opportunityData.description"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateOpp()"
        :to="{ name: 'apps-opportunity-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormTextarea,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      //select
      customerOptions: [],
      statusOptions: [],
      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },
      userData: store.state.user.userData,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      responsibleOptions: []
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  async created() {
    var customerList = [];
    var customerId = [];
    await this.getResponsibleOptions()
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });

    var optionsList = [];
    await axios
      .get(`${URL_API}opportunity_status`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.statusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });
  },

  methods: {
    async getResponsibleOptions() {
      try {
        const response = await axios.get(`${URL_API}user/manager-commercial`, {
        headers: getHeader(this.userData),
      })
      
      this.responsibleOptions = response.data.map(user => {
        return {
          label: user.email,
          value: user.id
        }
      })
      } catch (e) {
        console.error("Error getting responsible options", e)
      }
    },
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    updateOpp() {
      if (this.$i18n.locale === "en") {
        this.opportunityData.value = Number(
          this.opportunityData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.opportunityData.value = this.switchDotsAndCommas(
          this.opportunityData.value
        );
        this.opportunityData.value = Number(
          this.opportunityData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      axios({
        method: "put",
        url: `${URL_API}opportunity/${this.opportunityData.id}`,
        headers: getHeader(this.userData),
        data: this.opportunityData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },
  },

  setup() {
    const opportunityData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}opportunity/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        opportunityData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          opportunityData.value = undefined;
        }
      });

    return {
      opportunityData,
      vSelect,
    };
  },
};
</script>

<style></style>
